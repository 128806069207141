import { useEffect, useMemo, useState } from "react";
import * as jose from "jose";

type Jwt = {
    exp?: number;
    sub?: string;
    tenant?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
};

const useLocalStorage = (key: string): string | null => {
    const [value, setValue] = useState(localStorage.getItem(key));

    useEffect(() => {
        setValue(localStorage.getItem(key));
    }, [key]);

    useEffect(() => {
        const handleStorageUpdate = (event: StorageEvent) => {
            if (event.key === key) {
                setValue(event.newValue);
            }
        };
        window.addEventListener("storage", handleStorageUpdate);
        return () => {
            window.removeEventListener("storage", handleStorageUpdate);
        };
    }, [key]);

    return value;
};

const parseAndValidateJwt = (rawToken: string, tenant: string): Jwt => {
    let jwt: Jwt;
    try {
        jwt = jose.decodeJwt(rawToken);
    } catch (e) {
        throw new Error(`Could not parse JWT token: ${e}`);
    }

    if (jwt.tenant !== tenant) {
        return {};
    }
    return jwt;
};

export const useCurrentUser = () => {
    const jwtKey = process.env.REACT_APP_JWT_KEY;
    if (!jwtKey) {
        throw new Error("REACT_APP_JWT_KEY is required");
    }
    const rawToken = useLocalStorage(jwtKey) ?? "";
    const tenant = useLocalStorage("tenant") ?? "";
    const [jwt, setJwt] = useState<Jwt>(parseAndValidateJwt(rawToken, tenant));

    useMemo(() => {
        const newJwt = parseAndValidateJwt(rawToken, tenant);
        setJwt(newJwt);
    }, [rawToken, tenant]);

    return {
        id: jwt.sub,
        email: jwt.email,
        firstName: jwt.firstName,
        lastName: jwt.lastName,
        tenant: jwt.tenant,
    };
};

export const useCurrentUserIdOrThrow = () => {
    const { id } = useCurrentUser();
    if (!id) {
        throw new Error("No valid JWT token found");
    }
    return id;
};
